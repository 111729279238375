<template>
  <div class="BannerAritcle">
    <div class="TypeSelect">
        过滤器（暂无）
    </div>
    <div class="Item" v-for="(item,index) in userbloglist" :key="index" @click="BrowserBlog(item.id)">
        <div class="UpItem">
            <div class="Title">{{item.title}}</div>
            <div class="Time">{{item.createTime}}</div>
        </div>
        <div class="MiddleItem">
            <div class="Tags" v-for="(itemtag,index) in item.keywords" :key="index">{{itemtag}}</div>
        </div>
        <div class="DownItem">
            <div v-if="!item.hasLike">
                <div class="ReadCount" @click.stop="_isLike(item.id)">
                    <i class="iconfont icon-dianzan"></i>
                    <span class="count">{{item.likeCount}}</span>赞
                </div>
            </div>
            <div v-else>
                <div class="ReadCount" @click.stop="_unLike(item.id)">
                    <i class="iconfont icon-dianzan1"></i>
                    <span class="count">{{item.likeCount}}</span>赞
                </div>
            </div>
            <div class="Comments"><i class="el-icon-chat-dot-square"></i><span class="count">{{item.commentCount}}</span></div>
            <div v-if="item.overt" class="Overt">
                <i class="iconfont icon-eye-close eyeclose"></i>他人不可见
            </div>
            <div v-else class="Overt">
                <i class="iconfont icon-eye eye"></i>他人可见
            </div>
            <div class="Edit" @click.stop="EditBlog(item.id)">编辑</div>
            <div class="Browser" @click.stop="BrowserBlog(item.id)">浏览</div>
            <div class="More">
                <i class="el-icon-delete" @click.stop="DeleteArticle(item.id)"></i>
            </div>
        </div>
    </div>
    <div class="Footer">
        <el-button size="mini" class="PreBtn" :disabled="preable" @click="PreItem">上一页</el-button>
        <el-button size="mini" class="NextBtn" :disabled="nextable" @click="NextItem">下一页</el-button>
    </div>
  </div>
</template>

<script>
import { isLike,unLike } from '../../../api/like';
import { GetUserBlog ,DeleteBlog} from '../../../api/blog';
export default {
    name:'UserArticle',
    data(){
        return{
            userbloglist:[],
            pageNo:1,
            pageSize:5,

            count:1,
            preable:true,
            nextable:false
        }
    },
    created(){
        this.GetBlog()
    },
    mounted(){

    },
    methods:{
        PreItem(){
            this.count -= 1
            if(this.count == 1){
                this.preable = true
                this.nextable = false
                this.pageNo = this.count
                GetUserBlog(this.pageNo,this.pageSize).then(res=>{
                    this.userbloglist = res.data.list
                })
            }else{
                this.nextable = false
                this.pageNo = this.count
                GetUserBlog(this.pageNo,this.pageSize).then(res=>{
                    this.userbloglist = res.data.list
                   
                    
                })
            }
        },
        NextItem(){
            this.count += 1
            this.preable = false
            this.pageNo = this.count
            GetUserBlog(this.pageNo,this.pageSize).then(res=>{
                this.userbloglist = res.data.list
                if(this.userbloglist.length == this.pageSize){
                    this.nextable = false
                }else{
                    this.nextable = true
                }
            })
        },
        async _isLike(id){
            let res = await isLike(id)
            this.$message({
                message: '点赞成功',
                type: 'success'
            });
            this.GetBlog()
        },
        async _unLike(id){
            let res = await unLike(id)
            this.$message({
                message: '取消点赞成功',
                type: 'success'
            });
            this.GetBlog()
        },
        async GetBlog(){
            let res= await GetUserBlog(this.pageNo,this.pageSize)
            this.userbloglist = res.data.list
        },
        EditBlog(id){
            this.$router.push({path:`/editor/blog/${id}`})
        },
        BrowserBlog(id){
            this.$router.push({
                path:`/article/details/${id}`
            })
        },  
        DeleteArticle(e){
            this.$confirm('是否继续删除该文章?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                DeleteBlog(e).then(res=>{
                    this.$message({message: '删除成功',type: 'success'});
                    this.GetBlog()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    }
}
</script>

<style scoped>
.BannerAritcle{
    position: relative;
    width: 100%;
}
.TypeSelect{
  height: 30px;
  width: 100%;
  padding: 5px 0;
  background-color: #b8b8b8;
  font-size: 14px;
  line-height: 30px;
}
.Item{
    padding: 20px 0px 10px 0;
    border-bottom:1px dotted #ddd;
}
.Item:hover{
    background-color: rgb(238, 238, 238);
}
.UpItem{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0px 5px 10px 10px;
}
.Title{
    font-size: 18px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    flex:  0 0 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.Title:hover{
    color: #f76400;
    cursor: pointer;
}
.Time{
    flex: 1;
    text-align: right;
    height: 20px;
    font-size: 13px;
    line-height: 20px;
    color:grey;
}
.MiddleItem{
    height: 100%;
    padding: 0px 5px 10px 10px;
    display: flex;
    align-items: center;
}
.Tags{
    height: 12px;
    width: auto;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #999aaa;
    line-height: 12px;
    padding: 4px;
    margin-right: 20px;
    border-radius: 3px;
}
.DownItem{
    position: relative;
    height: 20px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
}
.ReadCount ,.Comments{
    height: 20px;
    width: auto;
    padding: 0px 5px;
    line-height: 20px;
    font-size: 15px;
    color:grey;
    cursor: pointer;
}
.ReadCount:hover{
    color: rgb(172, 170, 170);
}
.count{
    padding: 0px 5px;
}
.Overt{
    position: absolute;
    font-size: 14px;
    right: 110px;
    height: 20px;
    line-height: 20px;
    cursor: default;
}
.eye , .eyeclose{
    font-size: 14px;
    padding: 0px 4px;
}
.Edit{
    position: absolute;
    right: 66px;
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    padding: 0px 5px;
    cursor: pointer;
    font-weight: 600;
}
.Browser{
    position: absolute;
    right: 24px;
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    padding: 0px 5px;
    cursor: pointer;
    font-weight: 600;
}
.More{
    position: absolute;
    display: flex;
    box-sizing: content-box;
    flex-direction: column;
    justify-content: right;
    right: 0;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    padding: 0px 5px;
    cursor: pointer;
}
.el-icon-delete{
    font-size: 16px;
    padding-top: 1px;
}
.Footer{
    display: flex;
    height: 40px;
    background: white;
    align-items: center;
}
.PreBtn{
    position: absolute;
    left: 10px;
}
.NextBtn{
    position: absolute;
    right: 10px;
}
</style>